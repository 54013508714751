import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { myStudioBasicProductID, myStudioExpandedProductID } from '../utils/consts';
import { ofakimApiUrl } from '../utils/urls';
import { removeSubstringBetweenPlusAndAt } from '../utils/email';
import { getCetEnvironmentInfo } from 'cet-components-lib/dist/utilities/cetEnvironment';
import { useSessionData } from 'cet-components-lib/dist/hooks';

import {
  setUserData,
  updateUserBasicProfileInfo,
  updateUserIsExtendedCatalog,
  updateLastSchoolData,
  updateUserLastSchool,
  updateUserFavorites,
  setUserBooks,
  setUserCourses,
  setUserActivities,
  updateActivity,
  updateProductSubscriptions,
  setPersonalBanners,
  setSchoolYear,
  setUILanguage
} from '../state/user';

import { setSectors } from '../state/sectors';

import { useSearch, useGenderFormatMessage } from './';
import { callWithCache } from '../utils/callWithCache';
import { CacheProvider } from '../utils/cacheProvider';

let sessionKey = null;

export const useUserProfile = (ssoListener = false, isInTaskManagerIframe = false) => {
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const dispatchSearch = useSearch();
  const { getGenderFormatMessage } = useGenderFormatMessage();
  const { cetDomain } = getCetEnvironmentInfo();
  const sessionData = useSessionData();

  const { dimensions, info, schools, lastSchool, isExtendedCatalog, booksActivities, productSubscriptions, activities, externalSchoolId, sectors, schoolYear } = useSelector(
    ({ dimensions, user, sectors }) => ({
      dimensions: dimensions.dimensions,
      info: user.info,
      schools: user.schools,
      lastSchool: user.lastSchool,
      favorites: user.favorites,
      isExtendedCatalog: user.info?.isExtendedCatalog,
      booksActivities: user.booksActivities,
      productSubscriptions: user.productSubscriptions,
      activities: user.activities,
      externalSchoolId: user.lastSchool?.externalSchoolId,
      sectors: sectors.sectors,
      schoolYear: user.schoolYear
    })
  );

  const composeUserCurrentSchool = useCallback(
    (schoolId, schools) => {
      let result = null;

      if (schools?.length) {
        if (!schoolId) {
          result = { ...schools[0] };
        } else {
          const schoolIdTrimmed = schoolId.trim();
          const lastSchool = schools.find(school => school.schoolId?.trim() === schoolIdTrimmed);
          result = { ...lastSchool };
        }

        if (result?.teacherAgeGrades) {
          result.masterClasses = result.teacherAgeGrades.filter(grade => grade.isClassMaster);
        }
      } else if (isInTaskManagerIframe) {
        result = { externalSchoolId: schoolId };
      }

      return result;
    },
    [isInTaskManagerIframe]
  );

  const loadProfile = useCallback(
    async userSessionData => {
      let userProfile = {};
      let isValidProfile = true;

      if (userSessionData && userSessionData.userId) {
        const displayName = `${userSessionData.firstName} ${userSessionData.lastName}`;
        const gender = userSessionData.gender?.toLowerCase() || 'female';
        const role = userSessionData.role?.toLowerCase() || 'guest';

        userProfile = { ...userSessionData, displayName, gender, role };

        if (dimensions?.ageGrades && dimensions?.disciplines) {

          if (role !== 'admin') {
            try {
              const teacherInfo = await callWithCache(window.cet.microservices.teachersapi.profile.getTeacherData, 'getTeacherData', { profileId: userSessionData.userId });
              userProfile = { ...userProfile, ...teacherInfo, email: removeSubstringBetweenPlusAndAt(userSessionData.email) };

              if (
                teacherInfo?.userId?.toLowerCase() !== userSessionData.userId.toLowerCase() ||
                !teacherInfo?.schools?.find(s => s?.externalSchoolId?.toLowerCase() === userSessionData.schoolId.toLowerCase())
              ) {
                isValidProfile = false;
              }
            } catch (ex) {
              isValidProfile = false;
            }
          }
        }

        userProfile?.schools?.forEach(school => {
          school.ageGrades = school.ageGradesIds
            ? school.ageGradesIds.split(',').map(id => {
              const grade = dimensions.ageGrades.find(dimension => dimension.id === id.trim());
              return grade
                ? {
                  ageGradeId: grade.id,
                  name: grade.name
                }
                : null;
            })
            : [];
          school.ageGrades = school.ageGrades.filter(grade => grade);

          school.teacherAgeGrades?.forEach(grade => {
            grade.name = dimensions.ageGrades.find(dimension => dimension.id === grade.ageGradeId)?.name;
            grade.disciplines = [];

            grade.disciplineIds?.forEach(disciplineId => {
              grade.disciplines.push({
                ...dimensions.disciplines.find(dimension => dimension.id === disciplineId)
              });
            });

            delete grade.disciplineIds;
          });
        });

        const lastSchool = composeUserCurrentSchool(userSessionData.schoolSign, userProfile.schools);
        userProfile = { ...userProfile, lastSchool, isValidProfile };
      }

      if (userProfile.email && userProfile.email.includes('@dummy.ac.il')) {
        userProfile.email = '';
      }

      userProfile.email = removeSubstringBetweenPlusAndAt(userProfile.email);

      dispatch(setUserData(userProfile));
      dispatch(setSectors(userProfile, locale))
      return userProfile;
    },
    [dimensions, dispatch, composeUserCurrentSchool]
  );

  const loadFavorites = useCallback(
    async teacherId => {
      if (teacherId) {
        window.cet.microservices.teachersapi.favorites.getFavorites({ teacherId }).then(favorites => {
          dispatch(updateUserFavorites(favorites));
        });
      } else {
        dispatch(updateUserFavorites({}));
      }
    },
    [dispatch]
  );

  const loadActivities = useCallback(
    async profileId => {
      try {
        if (profileId) {
          try {
            await window.cet.microservices.teachersapi.import.importCourseEnvironments({
              teacherId: profileId
            });
          } catch (ex) {
            console.warn(ex);
          }
        }

        const activities = profileId
          ? await window.cet.microservices.teachersapi.lmsActivity.getActivitiesByTeacherId({
            profileId
          })
          : [];

        dispatch(setUserActivities(activities));
      } catch (e) { }
    },
    [dispatch]
  );

  const initUser = useCallback(
    async user => {
      if (loadProfile && loadFavorites && loadActivities && dispatch) {
        if (user) {
          const userProfile = await loadProfile(user);

          if (userProfile?.isValidProfile && user.role.toLowerCase() === 'teacher' && !isInTaskManagerIframe) {
            loadFavorites(user.userId);
            loadActivities(user.userId);
          }
        } else {
          dispatch(setUserData({}));
          loadFavorites();
          loadActivities();
        }
      }
    },
    [isInTaskManagerIframe, dispatch, loadProfile, loadFavorites, loadActivities]
  );

  useEffect(() => {
    if (initUser && sessionData?.isSessionDataReady) {
      const newSessionKey = sessionData?.userId ? `${sessionData.userId}_${sessionData.schoolId}_${sessionData.role}` : '';

      if (sessionKey !== newSessionKey) {
        sessionKey = newSessionKey;

        if (sessionData?.role?.toLowerCase() === 'student') {
          const langSuffix = locale ? `/${locale}` : '';
          window.open(`https://students.myofek.${cetDomain}${langSuffix}`, '_self');
        } else {
          initUser(sessionData?.userId ? sessionData : null);
        }
      }
    }
    // if the accessManagement script is not loaded, init as guest user as fallback
    setTimeout(() => {
      if (!window.cet?.accessmanagement) {
        initUser(null);
      }
    }, 1000);
  }, [locale, cetDomain, sessionData, sessionData?.isSessionDataReady, initUser]);

  useEffect(() => {
    if (sessionData?.isSessionDataReady) {
      const localCacheKey = CacheProvider.generateKey('sessionData.user', sessionData.userId ?? '');
      if (!CacheProvider.get(localCacheKey)) {
        CacheProvider.clearSession();
      }

      CacheProvider.set(localCacheKey, sessionData.sessionId);
    }
  }, [sessionData]);

  useEffect(() => {
    let isActive = true;

    const getSortedMyCourses = userCourses => {
      if (userCourses && userCourses[0]?.data?.length && activities?.length) {
        userCourses[0].data = userCourses[0].data.sort((a, b) => {
          const aHasAudience = !!activities.find(
            activity =>
              activity.externalId === a.externalContextId &&
              activity.schoolProfileId &&
              activity.schoolProfileId.toLocaleUpperCase() === externalSchoolId &&
              !!activity.additionalInfo.audiences.find(audience => !audience.archiveYear)
          );
          const bHasAudience = !!activities.find(
            activity =>
              activity.externalId === b.externalContextId &&
              activity.schoolProfileId &&
              activity.schoolProfileId.toLocaleUpperCase() === externalSchoolId &&
              !!activity.additionalInfo.audiences.find(audience => !audience.archiveYear)
          );

          if (aHasAudience && !bHasAudience) {
            return -1;
          } else if (bHasAudience && !aHasAudience) {
            return 1;
          } else if (a.title < b.title) {
            return -1;
          }
          return 1;
        });
      }

      return userCourses;
    };

    const setCourses = async () => {
      const externalIds = productSubscriptions
        ?.filter(productSubscription => productSubscription.source === 'capsules' && !!productSubscription.folderID)
        .map(productSubscription => productSubscription.folderID);
      let userCourses = [];
      try {
        const result = externalIds && externalIds.length > 0 ? await dispatchSearch([{ externalContextId: externalIds }]) : [];
        if (result?.length) {
          let items = result[0];
          items.totalCount = items.data.length;
          items.title = getGenderFormatMessage('my_courses');
          userCourses = [items];
          dispatch(setUserCourses(getSortedMyCourses(userCourses)));
        } else {
          dispatch(setUserCourses([]));
        }
      } catch (e) {
        dispatch(setUserCourses([]));
      }
    };

    if (isActive) {
      if (productSubscriptions) {
        setCourses();
      } else {
        dispatch(setUserCourses(null));
      }
    }

    return () => {
      isActive = false;
    };
  }, [activities, dispatch, dispatchSearch, externalSchoolId, getGenderFormatMessage, productSubscriptions]);

  useEffect(() => {
    let isActive = true;
    const setBooks = async () => {
      try {
        const externalIds = booksActivities.map(activity => activity.externalId);
        const result = externalIds && externalIds.length > 0 ? await dispatchSearch([{ externalContextId: externalIds }, { dimensionParams: {} }]) : [];

        if (isActive) {
          if (result?.length) {
            let items = result[0];
            items.title = getGenderFormatMessage('my_books');

            // Filter books that added after schoolYear.startDate
            const filteredBooks = items.data.filter(item => {
              const book = booksActivities.find(book => book.externalId === item.externalContextId);
              const bookTimestamp = new Date(book.additionalInfo.timestmp);
              return bookTimestamp >= schoolYear.startDate;
            });

            items.data = filteredBooks;

            items.data.forEach(item => {
              const book = booksActivities.filter(book => book.externalId === item.externalContextId);
              item.activityInfo = book[0].additionalInfo;
              item.isInMyBooks = true;
            });

            items.data.sort((a, b) => (a.activityInfo.timestmp > b.activityInfo.timestmp ? -1 : a.activityInfo.timestmp < b.activityInfo.timestmp ? 1 : 0));
            dispatch(setUserBooks(items.data.length > 0 ? [items] : null));
          } else {
            dispatch(setUserBooks(null));
          }
        }
      } catch (e) {
        if (isActive) {
          dispatch(setUserBooks(null));
        }
      }
    };

    if (ssoListener) {
      setTimeout(() => {
        if (isActive) {
          if (booksActivities && booksActivities.length > 0 && sectors && sectors.length > 0) {
            setBooks();
          } else {
            dispatch(setUserBooks(null));
          }
        }
      }, 10);
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, dispatchSearch, getGenderFormatMessage, sectors, booksActivities, ssoListener]);

  const updateUILanguage = useCallback(
    async ({ teacherId = '', selectedLanguage = '' }) => {
      try {
        const newUILanguage = await window.cet.microservices.teachersapi.profile.setUILanguage({
          userId: teacherId,
          lastUILanguage: selectedLanguage
        });
        dispatch(setUILanguage(newUILanguage || selectedLanguage));
      } catch (e) {
        console.error('Falied to set new UI Language', e);
      }
    },
    [dispatch]
  );

  const updateUserActivity = useCallback(
    async ({ teacherId, externalId, activityType, name, additionalInfo, schoolProfileId }) => {
      try {
        const activity = await window.cet.microservices.teachersapi.lmsActivity.updateTeacherActivity({
          teacherId,
          externalId,
          activityType,
          name,
          additionalInfo,
          schoolProfileId
        });
        dispatch(updateActivity(activity));
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          console.error(`Failed to save activity`, error);
        }
      }
    },
    [dispatch]
  );

  const assigningFavoriteToCategories = useCallback(
    async updated => {
      const requestData = {
        teacherId: updated.teacherId,
        categoriesIds: updated.categoriesIds,
        contentCatalogId: updated.contentCatalogId,
        title: updated.title,
        itemContext: updated.itemContext
      };

      await window.cet.microservices.teachersapi.favorites.assigningFavoriteToCategories(requestData);
      await loadFavorites(info?.userId);
    },
    [info, loadFavorites]
  );

  const addFavoriteCategory = useCallback(
    async updated => {
      const requestData = {
        teacherId: updated.teacherId,
        name: updated.name
      };

      let id;
      await window.cet.microservices.teachersapi.favorites.setCategory(requestData).then(result => {
        id = result;
      });
      await loadFavorites(info?.userId);
      return id;
    },
    [info, loadFavorites]
  );

  const deleteFavoriteCategory = useCallback(
    async categoryId => {
      const requestData = {
        teacherId: info.userId,
        categoryId: categoryId
      };

      try {
        await window.cet.microservices.teachersapi.favorites.deleteCategory(requestData);
        await loadFavorites(info?.userId);
      } catch (ex) {
        /* TODO: Display Error Message: Can't add to categories */
        console.error(ex);
      }
    },
    [info, loadFavorites]
  );

  const renameFavoriteCategory = useCallback(
    async (categoryId, newName) => {
      const requestData = {
        id: categoryId,
        teacherId: info.userId,
        name: newName
      };

      try {
        await window.cet.microservices.teachersapi.favorites.setCategory(requestData);
        await loadFavorites(info?.userId);
      } catch (ex) {
        /* TODO: Display Error Message: Can't add to categories */
        console.error(ex);
      }
    },
    [info, loadFavorites]
  );

  const updateUserBasicProfile = useCallback(
    async updated => {
      if (!updated?.userId) return;

      const requestData = {
        userId: updated.userId,
        email: updated.email,
        thumbnailId: updated.thumbnailId,
        phone: updated.phone
      };

      const previousInfo = info;

      try {
        dispatch(updateUserBasicProfileInfo(updated));
        await window.cet.microservices.teachersapi.profile.updateProfile(requestData);
      } catch (ex) {
        dispatch(updateUserBasicProfileInfo(previousInfo));
        throw ex;
      }
    },
    [dispatch, info]
  );

  const updateIsExtendedCatalog = useCallback(
    async updated => {
      const requestData = {
        userId: updated.userId,
        isExtendedCatalog: updated.isExtendedCatalog
      };

      try {
        dispatch(updateUserIsExtendedCatalog(updated));
        await window.cet.microservices.teachersapi.profile.updateIsExtendedCatalog(requestData);
      } catch (ex) {
        console.error(ex);
      }
    },
    [dispatch]
  );

  const setTeacherGradesAndDisciplines = useCallback(
    async ageGrades => {
      const previousInfo = lastSchool;
      const updated = {
        ...lastSchool,
        teacherAgeGrades: ageGrades,
        masterClasses: ageGrades.filter(grade => grade.isClassMaster === true)
      };

      const requestData = {
        userId: info?.userId,
        schoolId: lastSchool.schoolId,
        ageGrades: ageGrades.map(grade => {
          const { ageGradeId, isClassMaster, disciplines } = grade;
          return {
            ageGradeId,
            isClassMaster,
            disciplineIds: disciplines.map(({ id }) => id)
          };
        })
      };

      try {
        dispatch(updateLastSchoolData(updated));
        await window.cet.microservices.teachersapi.profile.setTeacherGradesAndDisciplines(requestData);
      } catch (ex) {
        dispatch(updateLastSchoolData(previousInfo));
        throw ex;
      }
    },
    [dispatch, info, lastSchool]
  );

  const setLastSchool = useCallback(
    async schoolId => {
      const updated = composeUserCurrentSchool(schoolId, schools);

      if (updated) {
        try {
          dispatch(updateUserLastSchool(updated));
          await window.cet.microservices.teachersapi.profile.setCurrentSchool({
            userId: info?.userId,
            lastSchoolId: updated.schoolId
          });
        } catch (ex) { }
      } else {
        dispatch(setUserData({}));
      }
    },
    [dispatch, info, schools]
  );

  const uploadAvatar = async blob => {
    const file = new window.File([blob], blob.name, { type: blob.type });
    try {
      const enableUploadSanitizedToStorage = false; // Enable this flag to upload sanitized images to storage
      let url;

      if (enableUploadSanitizedToStorage) {
        const response = await uploadToSanitizeService(file);
        if (response?.data_id) {
          url = await uploadSanitizedToStorage(response.data_id);
          return url;
        } else {
          throw new Error('Failed to sanitize file');
        }
      } else { // Upload directly to storage (no sanitization)
        url = await window.cet.microservices.nairobiapi.fileUpload.upload({
          container: 'usersuploads',
          prefix: 'avatrs',
          file
        });
      }

      return url;
    } catch (e) {
      console.log(e)
      return '';
    }
  };

  /**
   * Uploads an image file to the sanitize service
   * @param {file} file - The file to be uploaded to the sanitize service
   * @returns {Promise<object | void>} - The response from the sanitize service
   */
  const uploadToSanitizeService = async (file) => {
    const options = {
      method: 'POST',
      body: file,
      headers: {
        filename: file.name,
        apikey: '580ba3ac12b54e959431c30cf36108ed',
        accept: 'application/json',
        'Content-Type': 'application/octet-stream'
      }
    };

    return fetch('https://cet.cybercloudnetworks.net/avsanitize', options)
      .then(responseObj => responseObj.json())
      .then(response => response)
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  /**
   * Sends the sanitized image dataId identifier to the storage service
   * @param {string} dataId
   * @returns {Promise<*>}
   */
  const uploadSanitizedToStorage = async (dataId) => {
    return await window.cet.microservices.nairobiapi.fileUpload.uploadSanitized({
      container: 'usersuploads',
      prefix: 'avatrs',
      dataId
    });
  };


  const updateUserAvatar = async blob => {
    const url = await uploadAvatar(blob);
    if (url) {
      const thumbnailId = url.split('/').pop();
      await updateUserBasicProfile({ ...info, thumbnailId });
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    let isActive = true;
    if (ssoListener) {
      const setProductSubscriptions = productSubscriptions => {
        if (isActive) {
          dispatch(updateProductSubscriptions(productSubscriptions));
        }
      };

      if (info && info.userId && lastSchool && lastSchool.externalSchoolId && !isInTaskManagerIframe) {
        callWithCache(window.cet.microservices.teachersapi.mybag.getProductSubscriptions, 'getProductSubscriptions', { teacherId: info.userId, getGeneralProducts: true })
          .then(subscriptions => {
            const productSubscriptions = subscriptions
              ? subscriptions.filter(
                subscription =>
                  (subscription.schoolID && subscription.schoolID.toLocaleUpperCase() === lastSchool.externalSchoolId.toLocaleUpperCase()) ||
                  subscription.productID === myStudioBasicProductID ||
                  subscription.productID === myStudioExpandedProductID
              )
              : [];
            setProductSubscriptions(productSubscriptions);
          })
          .catch(error => {
            setProductSubscriptions(null);
          });
      } else {
        setProductSubscriptions(null);
      }
    }
    return () => {
      isActive = false;
    };
  }, [ssoListener, isInTaskManagerIframe, info, lastSchool, dispatch]);

  useEffect(() => {
    let isActive = true;
    if (ssoListener && !isInTaskManagerIframe) {
      setTimeout(() => {
        if (isActive) {
          let langs = [locale];
          let userTypes = [info && info.userId ? 'teacher' : 'guest'];
          let sectors = isExtendedCatalog ? [] : lastSchool?.sectorId ? [lastSchool.sectorId] : locale === 'ar' ? ['arab'] : ['state', 'mmad'];
          let filter = [
            {
              isPublish: [true],
              langs: langs,
              userTypes: userTypes,
              sectors: sectors
            }
          ];
          if (lastSchool && lastSchool.teacherAgeGrades && lastSchool.teacherAgeGrades.length > 0) {
            filter = [];
            lastSchool.teacherAgeGrades.forEach(ageGrade => {
              filter.push({
                isPublish: [true],
                langs: langs,
                userTypes: userTypes,
                sectors: sectors,
                ageGrades: [ageGrade.ageGradeId],
                disciplines: ageGrade.disciplines && ageGrade.disciplines.length > 0 ? ageGrade.disciplines.map(discipline => discipline.id) : []
              });
            });
          }

          const setBanners = banners => {
            if (isActive) {
              banners.forEach(banner => {
                try {
                  banner.content = banner.content ? JSON.parse(banner.content) : null;
                } catch (error) {
                  banner.content = null;
                }
              });
              banners.filter(banner => banner.content);
              dispatch(setPersonalBanners(banners));
            }
          };
          callWithCache(window.cet.microservices.nairobiapi.banners.getBanners, 'getBanners', filter)
            .then(banners => {
              setBanners(banners);
            })
            .catch(error => {
              setBanners([]);
            });
        }
      }, 1);
    }

    return () => {
      isActive = false;
    };
  }, [dispatch, ssoListener, isInTaskManagerIframe, info, isExtendedCatalog, lastSchool, locale]);

  useEffect(() => {
    let isActive = true;

    if (ssoListener) {
      const getSchoolYear = () =>
        fetch(`${ofakimApiUrl}/lmscp/getSchoolYear?date=${new Date().toISOString()}`, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          },
          referrerPolicy: 'no-referrer',
          credentials: 'include',
          redirect: 'follow'
        }).then(response => {
          if (response.status !== 200) {
            throw new Error(response.json());
          } else {
            return response.json();
          }
        });

      callWithCache(getSchoolYear, 'getSchoolYear', null).then(schoolYear => {
        if (isActive && schoolYear && schoolYear.startDate && schoolYear.endDate) {
          try {
            const startDate = new Date(schoolYear.startDate);
            const endDate = new Date(schoolYear.endDate);
            const currentSchoolYear = endDate.getFullYear();
            dispatch(
              setSchoolYear({
                startDate,
                endDate,
                currentSchoolYear
              })
            );
          } catch (ex) { }
        }
      });
    }

    return () => {
      isActive = false;
    };
  }, [ssoListener, dispatch]);

  return {
    loadFavorites,
    assigningFavoriteToCategories,
    addFavoriteCategory,
    deleteFavoriteCategory,
    renameFavoriteCategory,
    setLastSchool,
    updateUserBasicProfile,
    updateIsExtendedCatalog,
    setTeacherGradesAndDisciplines,
    updateUserAvatar,
    updateUserActivity,
    updateUILanguage
  };
};
